<template>
  <div id="order-view-actions-spec-awaiting-quotation">
    <OrderViewActionAccept v-if="!isQuoteOwner && quoteSelected.quote.paymentTerm !== 'on_contract'" btn-icon="CreditCardIcon" :btn-text="$tc('payment.title', 1)" />
    <div v-if="isQuoteOwner">
      <b-button
        id="order-view-action-service-scheduling"
        v-ripple.400
        v-scroll-to="'#order-view-services'"
        variant="success"
        class="mb-75"
        block
        :disabled="disabled || !quoteSelected.quote.lines.length"
      >
        <feather-icon icon="CheckIcon" size="20" class="align-middle" />
        <span class="ml-25 align-middle"> {{ $t('action.service_scheduling') }} </span>
      </b-button>
    </div>
    <div v-else>
      <b-button
        v-if="quoteSelected.checkout"
        id="order-view-actions-spec-awaiting-quotation-add-item"
        v-ripple.400
        class="mb-75"
        block
        :disabled="disabled"
        :to="{
          name: 'provider-list',
          params: {
            trip_id: quoteSelected.checkout.tripId,
            step_id: quoteSelected.checkout.stepId,
            event_id: quoteSelected.checkout.stepEventId,
            airport_id: quoteSelected.checkout.airportId,
          },
        }"
      >
        <feather-icon icon="PlusIcon" size="20" class="align-middle" />
        <span class="ml-25 align-middle"> {{ $t('action.add_item') }} </span>
      </b-button>
      <OrderViewActionCancelAndEdit
        :disabled="disabled"
        :btn-text="$t('order.cancel_and_recall_order')"
        btn-icon="RepeatIcon"
        :confirm-title="$t('order.cancel_and_recall_order')"
        :confirm-question="$t('order.order.edit_question')"
        :confirm-text="$t('order.order.edit_text_fo')"
      />
    </div>
    <OrderViewActionCancelOrRefuse :disabled="disabled" btn-icon="XCircleIcon" :btn-text="$t('order.cancel_order')" :refuse-title="$t('order.cancel_order')" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import OrderViewActionCancelOrRefuse from '@/views/order/OrderViewActionCancelOrRefuse.vue'

export default {
  name: 'OrderViewActionsSpecAwaitingQuotation',

  components: {
    OrderViewActionAccept: () => import('@/views/order/OrderViewActionAccept.vue'),
    OrderViewActionCancelAndEdit: () => import('@/views/order/OrderViewActionCancelAndEdit.vue'),
    OrderViewActionCancelOrRefuse,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'isQuoteOwner']),
  },
}
</script>
