var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"order-view-actions-spec-awaiting-quotation"}},[(!_vm.isQuoteOwner && _vm.quoteSelected.quote.paymentTerm !== 'on_contract')?_c('OrderViewActionAccept',{attrs:{"btn-icon":"CreditCardIcon","btn-text":_vm.$tc('payment.title', 1)}}):_vm._e(),(_vm.isQuoteOwner)?_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",modifiers:{"400":true}},{name:"scroll-to",rawName:"v-scroll-to",value:('#order-view-services'),expression:"'#order-view-services'"}],staticClass:"mb-75",attrs:{"id":"order-view-action-service-scheduling","variant":"success","block":"","disabled":_vm.disabled || !_vm.quoteSelected.quote.lines.length}},[_c('feather-icon',{staticClass:"align-middle",attrs:{"icon":"CheckIcon","size":"20"}}),_c('span',{staticClass:"ml-25 align-middle"},[_vm._v(" "+_vm._s(_vm.$t('action.service_scheduling'))+" ")])],1)],1):_c('div',[(_vm.quoteSelected.checkout)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"id":"order-view-actions-spec-awaiting-quotation-add-item","block":"","disabled":_vm.disabled,"to":{
        name: 'provider-list',
        params: {
          trip_id: _vm.quoteSelected.checkout.tripId,
          step_id: _vm.quoteSelected.checkout.stepId,
          event_id: _vm.quoteSelected.checkout.stepEventId,
          airport_id: _vm.quoteSelected.checkout.airportId,
        },
      }}},[_c('feather-icon',{staticClass:"align-middle",attrs:{"icon":"PlusIcon","size":"20"}}),_c('span',{staticClass:"ml-25 align-middle"},[_vm._v(" "+_vm._s(_vm.$t('action.add_item'))+" ")])],1):_vm._e(),_c('OrderViewActionCancelAndEdit',{attrs:{"disabled":_vm.disabled,"btn-text":_vm.$t('order.cancel_and_recall_order'),"btn-icon":"RepeatIcon","confirm-title":_vm.$t('order.cancel_and_recall_order'),"confirm-question":_vm.$t('order.order.edit_question'),"confirm-text":_vm.$t('order.order.edit_text_fo')}})],1),_c('OrderViewActionCancelOrRefuse',{attrs:{"disabled":_vm.disabled,"btn-icon":"XCircleIcon","btn-text":_vm.$t('order.cancel_order'),"refuse-title":_vm.$t('order.cancel_order')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }